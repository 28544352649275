<template>
  <div id="user-editor-modal">

    <SuccessPopup
      :header="success_header_message"
      :text="success_text_message"
    />


    <b-modal ref="edit-todo-modal" size="xl" :ok-title="$t('COMMON.SAVE')" hide-footer>
      <TodoEditor
        ref="todo-editor"
        :todo="todo"
        @created="todo_created"
        @updated="todo_updated"
        @published="todo_published"
      />
    </b-modal>

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import TodoEditor from '@/view/pages/ml/todo/TodoEditor.vue';
import dayjs from 'dayjs';
import SuccessPopup from '@/view/components/SuccessPopup.vue';

export default {
  name: 'todo-editor',
  components: {
    TodoEditor,
    SuccessPopup
  },
  mixins: [toasts],
  computed: {

  },
  props: ['todo'],
  emits: ['created', 'updated', 'published'],
  watch: {

  },
  async mounted() {

  },
  methods: {

    show() {
      this.$refs['edit-todo-modal'].show();
    },

    hide() {
      this.$refs['edit-todo-modal'].hide();
    },

    todo_created(todo) {
      this.$emit('created', todo);

      this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.CREATED'));
    },

    todo_updated(todo) {
      this.$emit('updated', todo);

      this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.UPDATED'));

      this.hide();
    },

    todo_published(todo) {
      this.success_text_message = this.$t('TODO.PUBLISH_SUCCESS_INFO');
      this.success_header_message = this.$t('TODO.PUBLISH_SUCCESS_HEADER');


      this.$emit('published', todo);

      this.hide();
    },

    // need to create todos because we want tasks to be added to it
    createTodo() {
      this.$nextTick(()=>{
        this.$refs['todo-editor'].createTodo();
      });
    }

  },
  data() {
    return {
      success_text_message: null,
      success_header_message: null,
    };
  },
};
</script>
<style lang="scss" scoped>

</style>
