<template>
  <div id="user-editor">

    <ErrorPopup
      :error="error_popup_message"
    />


    <Confirm
      :title="$t('TODO.CONFIRM_PUBLISH')"
      type="primary"
      :message="$t('TODO.CONFIRM_PUBLISH_INFO')"
      ref="confirm_publish_popup"
      @confirm="publish_todo_confirm_clicked"
    />


    <b-form class="mt-8">
        <b-form-group id="input-group-todo_id" label="ID" label-for="input-todo_id">
          <b-form-input
            id="input-todo_id"
            v-model="local_todo.todo_id"
            type="text"
            required
            disabled
          ></b-form-input>
        </b-form-group>

        <div v-if="local_todo.status === 'DRAFT'">
          <b-alert show variant="warning"
            >{{ $t('TODO.NOT_PUBLISHED_INFO') }}</b-alert
          >
          <b-button type="button" variant="primary" @click="show_confirm_publish_popup">{{ $t('TODO.PUBLISH')}}</b-button>
        </div>

        <div v-if="local_todo.status !== 'DRAFT' && local_todo.status !== 'INVALID'">
          <b-alert show variant="primary"
            >{{ $t('TODO.PUBLISHED_INFO') }}</b-alert>
          <!--<b-button type="button" variant="danger">Återkalla ärendet</b-button>-->
        </div>

        <b-form-group
          id="input-group-6"
          :label="$t('TODO.COMPANIES')"
          label-for="input-6"
          class="mt-8"
        >
            <Multiselect
              class="multiselect-blue"
              v-model="local_todo.company_ids"
              mode="tags"
              :placeholder="$t('COMPANY.ALL_COMPANIES')"
              :close-on-select="false"
              :searchable="true"
              :options="company_options"
            >
              <template #beforelist>
                <div
                  class="multiselect-option"
                  @mousedown.prevent
                  @click="toggle_all_company_ids"
                >
                  <span>
                    <inline-svg :src="icon" />
                    {{ $t('COMMON.SELECT_ALL') }}
                  </span>
                </div>
                <v-divider class="my-2" />
              </template>
            </Multiselect>

        </b-form-group>

        <b-form-group id="input-group-1" :label="$t('TODO.NAME')" label-for="input-1">
          <b-form-input id="input-1" v-model="local_todo.name" type="text"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-4" :label="$t('TODO.DESCR')" label-for="input-4">
          <b-textarea id="input-4" v-model="local_todo.descr" rows="6" class="mb-2"> </b-textarea>
        </b-form-group>


        <b-form-group
          id="input-group-from_datetime"
          :label="$t('TODO.FROM_DATETIME')"
          label-for="input-from_datetime"
        >
          <memlist-date-picker v-model="local_todo.from_datetime"></memlist-date-picker>
        </b-form-group>

        <b-form-group
          id="input-group-to_datetime"
          :label="$t('TODO.TO_DATETIME')"
          label-for="input-to_datetime"
        >
          <memlist-date-picker v-model="local_todo.to_datetime"></memlist-date-picker>
        </b-form-group>


        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                v-model="local_todo.send_email"
                @click="local_todo.send_email = !local_todo.send_email"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"
              >{{ $t('TODO.SEND_TO_ADMINS_ON_STATUS') }}</span
            >
          </div>
        </b-form-group>

        <b-row>
          <b-col lg="12">
            <TaskTable
              :tasks="tasks"
              :status="local_todo.status"
              @create_task_clicked="create_task_clicked"
              @select_task_clicked="select_task_clicked"
              @delete_task_clicked="delete_task_clicked"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <TaskEdit
              ref="editTask"
              :todo="local_todo"
              :tasks="tasks"
              @task_updated="task_updated"
              @todo_updated="todo_updated"
              />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <b-alert v-if="tasks.length === 0" show variant="warning"
              >{{ $t('TODO.NO_SUBTASKS_INFO') }}</b-alert
            >
          </b-col>
        </b-row>

        <b-row class="mt-8">
          <b-col lg="12">
            <RightSaveButton
              ref="saveButton"
              :text="$t('COMMON.SAVE')"
              @clicked="save_clicked" />
          </b-col>
        </b-row>
      </b-form>


  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


import TaskTable from '@/view/pages/ml/todo/TaskTable.vue';
import TaskEdit from '@/view/pages/ml/todo/TaskEdit.vue';
import dayjs from 'dayjs';


import Confirm from '@/view/components/Confirm.vue';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

export default {
  name: 'todo-editor-modal',
  components: {
    TaskTable,
    TaskEdit,
    Confirm,
    RightSaveButton,
    ErrorPopup,
    Multiselect,
  },
  mixins: [toasts],
  computed: {
    has_all_company_ids_selected() {
      return this.local_todo.company_ids.length === this.available_companies.length;
    },
    has_some_company_ids_selected() {
      return this.local_todo.company_ids.length !== 0 && this.local_todo.company_ids.length < this.available_companies.length;
    },
    icon () {
      if (this.has_all_company_ids_selected) { return '/assets/svg/check-square.svg' }
      if (this.has_some_company_ids_selected) { return '/assets/svg/dash-square.svg' }
      return '/assets/svg/square.svg'
    },
    company_options() {
      if (!this.available_companies) {
        return [];
      }

      return this.available_companies.map((item) => ({ label: item.name, value: item.company_id }));
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies']),
  },
  props: ['todo','todonames'],
  emits: ['updated', 'published'],
  watch: {
    currentCompanyId() {
      this.get_hierarchy_companies();
    },

    todo: {
      deep: true,
      handler(val) {
        this.local_todo = { ...this.todo }

        this.load_tasks();
      }
    },
  },
  async mounted() {

    this.local_todo = { ...this.todo }

    if (!this.local_todo.company_ids) {
      this.local_todo.company_ids = [];
    }

    await this.load_tasks();
    await this.get_hierarchy_companies();
  },
  methods: {


    async get_hierarchy_companies() {
      try {
        const res = await axios.get(`/company/hierarchy/${this.currentCompanyId}`);

        if (res.status === 200) {
          const company_list = [];

          // dont include parent
          this.popuplate_hierarchy_recursive(res.data, company_list);

          company_list.sort((a,b)=>{
            if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
            return 1;
          });

          this.available_companies = company_list;
        }
      }
      catch (err) {
        console.error('get_hierarchy_companies error', err);
      }
    },

    popuplate_hierarchy_recursive(parent, list_companies) {
      for (const c of parent.children) {
        list_companies.push(c);

        this.popuplate_hierarchy_recursive(c, list_companies);
      }
    },

    toggle_all_company_ids () {
      this.$nextTick(() => {
        if (this.has_all_company_ids_selected) {
          this.local_todo.company_ids = []
        } else {
          this.local_todo.company_ids = this.company_options.map(item => item.value).slice()
        }
      })
    },

    create_task_clicked() {
      this.$refs['editTask'].create_task_clicked();
    },

    select_task_clicked(todo_task_id) {
      this.$refs['editTask'].selectTask(todo_task_id);
    },

    async delete_task_clicked(todo_task_id) {
      try {
        const res = await axios.delete(`/todo_task/${todo_task_id}`)

        if (res.status === 204) {
          this.tasks = this.tasks.filter(item => item.todo_task_id !== todo_task_id);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.SUBTASK_DELETED'));

          //this.$emit('task_updated', todo_task_id);
          this.task_updated(todo_task_id);

          return;
        }

      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_DELETE_SUBTASK'));

    },

    save_clicked() {
      this.create_or_update_todo();
    },

    show_confirm_publish_popup() {
      this.$refs['confirm_publish_popup'].show();
    },


    publish_todo_confirm_clicked() {

      // perform some validations and present CLEARLY to the user

      if (!this.local_todo.company_ids || !Array.isArray(this.local_todo.company_ids) || this.local_todo.company_ids.length === 0) {
        this.error_popup_message = 'ERR_TODO_MUST_SET_COMPANY_IDS';
        this.$nextTick(()=>{ this.error_popup_message = null; });
        return;
      }

      if (!dayjs(this.local_todo.from_datetime).isValid()) {
        this.error_popup_message = 'ERR_TODO_SET_DATE';
        this.$nextTick(()=>{ this.error_popup_message = null; });
        return;
      }

      if (!dayjs(this.local_todo.to_datetime).isValid()) {
        this.error_popup_message = 'ERR_TODO_SET_DATE';
        this.$nextTick(()=>{ this.error_popup_message = null; });
        return;
      }

      if (!this.tasks || this.tasks.length === 0) {
        this.error_popup_message = 'ERR_TODO_MISSING_SUBTASKS';
        this.$nextTick(()=>{ this.error_popup_message = null; });
        return;
      }

      // proceed to actually publish
      this.publish_todo();
    },

    async publish_todo() {
      const loader = this.$loading.show();

      try {
        const res = await axios.post(`/todo/publish/${this.local_todo.todo_id}`, this.local_todo);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.PUBLISHED'));
          loader && loader.hide();

          this.$emit('published', res.data);

          return;
        }
      }
      catch (err) {
        console.error('todo publish error', err);
      }

      this.toastr('danger', this.$t('COMMON.OK'), this.$t('TODO.UNABLE_PUBLISH'));
      loader && loader.hide();

    },


    todo_updated(todo) {
      this.local_todo = todo;
    },
    task_updated(task_id) {
      this.load_tasks();
    },
    async load_tasks() {

      if (!this.local_todo.todo_id) {
        this.tasks = [];
        return;
      }

      try {
        const res = await axios.get(`/todo_task?todo_id=${this.local_todo.todo_id}`);

        if (res.status === 200) {
          this.tasks = [ ...res.data ];
          return;
        }

      }
      catch (err) {
        console.error('load_tasks error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_GET_SUBTASKS'));

    },
    getAvailableName(name, todo_id = -1) {
      let tempName = name;
      if (todo_id == -1) {
        while (this.todonames.findIndex(item => item == tempName) > -1) {
          let nIndex = tempName.indexOf('#');
          if (nIndex > -1) {
            let number = parseInt(tempName.substring(nIndex + 1));
            let strReplaced = '#' + (number + 1);
            tempName = tempName.replace(tempName.substring(nIndex), strReplaced);
          } else {
            tempName += ' #1';
          }
        }
      } else {
        while (this.todonames.findIndex(item => item == tempName) > -1) {
          let numberth = this.todonames.findIndex(item => item == tempName);
          if (this.todoids[numberth] == todo_id) {
            if (
              this.todonames.findIndex((index, item) => item == tempName && index > numberth) == -1
            )
              break;
          }
          let nIndex = tempName.indexOf('#');
          if (nIndex > -1) {
            let number = parseInt(tempName.substring(nIndex + 1));
            let strReplaced = '#' + (number + 1);
            tempName = tempName.replace(tempName.substring(nIndex), strReplaced);
          } else {
            tempName += ' #1';
          }
        }
      }
      return tempName;
    },

    getAvailableNameActive(name, todo_id = -1) {
      let tempName = name;

      if (todo_id == -1) {
        while (this.activenames.findIndex(item => item == tempName) > -1) {
          let nIndex = tempName.indexOf('#');
          if (nIndex > -1) {
            let number = parseInt(tempName.substring(nIndex + 1));
            let strReplaced = '#' + (number + 1);
            tempName = tempName.replace(tempName.substring(nIndex), strReplaced);
          } else {
            tempName += ' #1';
          }
        }
      }

      return tempName;
    },

    async create_or_update_todo() {

      this.local_todo.creator_company_id = this.currentCompanyId;
      this.local_todo.period_id = this.currentPeriodId;

      if (!this.local_todo.todo_id) {
        try {
          const res = await axios.post('/todo', this.local_todo)

          if (res.status === 201) {
            this.local_todo = res.data;

            this.$emit('updated', this.local_todo);

            this.$refs['saveButton'].stop();
            return;
          }
        }
        catch (err) {
          console.error('create_or_update_todo error creating todo', err);
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_CREATE'));
        this.$refs['saveButton'].stop();

      } else {

        try {
          const res = await axios.put(`/todo/${this.local_todo.todo_id}`, this.local_todo);

          if (res.status === 200) {
            this.local_todo = res.data;

            this.$emit('updated', this.local_todo);
            this.$refs['saveButton'].stop();

            return;
          }
        }
        catch (err) {
          console.error('create_or_update_todo error updating todo', err);
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_UPDATE'));
        this.$refs['saveButton'].stop();
      }
    },

    createTodo() {
      this.resetField();
      this.create_or_update_todo();
    },


    resetField() {
      this.local_todo = {
        id: '',
        from_datetime: '',
        to_datetime: '',
        status: 'DRAFT',
        type: 'PA',
        creator_company_id: this.currentCompanyId,
        company_id: this.currentCompanyId,
        period_id: this.currentPeriodId,
        company_ids: [],
        name: 'Nytt ärende'
      };
      this.tasks = [];
    },

  },
  data() {
    return {
      error_popup_message: null,
      TODO_STATUS: {
        WAITING: 'WAIT',
        DRAFT: 'DRAFT',
        NOT_DONE: 'NOT',
        DONE: 'DONE',
        CONFIRMED: 'CN',
        DELETED: 'DEL',
        ACCEPTED: 'ACC',
        REJECTED: 'REJ',
        BACKLOG: 'BAC',
        FUTURE: 'FUT',
        CLOSE: 'CLOSE',
        INVALID: 'INVALID'
      },
      TODO_TYPE: {
        PARENT: 'PA',
        FILE_UPLOAD: 'FU',
        CONFIRM: 'CF',
        COMPANY: 'CO',
        SYSTEM: 'SY'
      },
      local_todo: {
        id: '',
        from_datetime: '',
        to_datetime: '',
        name: '',
        status: 'DRAFT',
        type: 'PA',
        send_email: true,
        company_ids: []
      },
      tasks: [],
      available_companies: []


    };
  },
};
</script>

<style lang="scss" scoped>
.multiselect-blue {
  --ms-tag-bg: #DBEAFE;
  --ms-tag-color: #2563EB;
}

.multiselect-option {
  &:hover {
    background-color: var(--ms-option-bg-pointed, #f3f4f6);
    color: var(--ms-option-color-pointed, #1f2937);
  }
}
</style>
