<template>
  <div id="page-todos">
    <h5 class="m-4">{{ $t('TODO.HEADER') }}</h5>
    <Confirm
      ref="confirmModal"
      type="danger"
      :message="$t('COMMON.ARE_YOU_SURE')"
      @confirm="delete_todo_clicked(selectedTodoId)"
    />

    <FilesListModal
      ref="filesListModal"
      :task="selected_task"
      @delete_file_clicked="delete_file_clicked"
    />

    <b-modal ref="manageLiveTodo" size="lg" hide-footer>
      <div>
        <b-alert show variant="secondary"
          >När alla åtgärder i ärendet är avklarat så bör du stänga ärendet. När ärendet är stängt
          kommer inga notifikationer att skickas ut, samt att ärendet hamnar i "Avslutade
          ärenden"</b-alert
        >

        <template v-for="(item, i) in selected_tasks">
          <b-alert v-if="item.status !== 'DONE'" v-bind:key="i" show variant="warning"
            >Åtgärd "{{ item.name }}" är ej klart</b-alert
          >
        </template>

        <b-button type="button" variant="outline-danger" @click="closeTodoClicked"
          >{{ $t('TODO.CLOSE') }}</b-button
        >
        <b-button type="button" variant="danger" class="ml-4" @click="confirm_delete"
          >{{ $t('TODO.DELETE') }} </b-button
        >
        <b-button type="button" variant="secondary" class="ml-4" @click="closeManageModalClicked"
          >{{ $t('COMMON.CANCEL') }}</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="chatBoxModal" size="lg" hide-footer>
      <ChatBox :comments="comments" />

      <b-row>
        <b-col lg="8">
          <b-form-group id="input-group-text" label="Skriv meddelande" label-for="input-text">
            <b-textarea id="input-text" v-model="comment" class="mb-2"> </b-textarea>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-button
            type="button"
            style="margin-top: 26px; height: 55px; width: 120px;"
            variant="primary"
            @click="sendCommentClicked"
            >Skicka</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <b-card title="" class="mb-2" hide-footer>
      <v-tabs v-model="tab" background-color="white" color="dark" left>
        <v-tab>Utkast</v-tab>
        <v-tab>Aktiva ärenden</v-tab>
        <v-tab>Avslutade ärenden</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item class="mt-8">
          <TodoTable
            type="draft"
            searchLabel="Sök ärende"
            :statusName="statusName"
            :todos="draft_todos"
            @updated="draft_todo_updated"
            @create_todo_clicked="create_todo_clicked"
            @select_todo_clicked="select_todo_clicked"
            @delete_todo_clicked="delete_todo_clicked"
          />

          <TodoEditModal
            ref="todoEdit-draft"
            :todo="selected_todo"
            @updated="todo_updated"
            @published="todo_published"
          />
        </v-tab-item>

        <v-tab-item class="mt-8">
          <b-input-group class="mb-2">
            <b-form-input
              v-model="search"
              type="search"
              placeholder="Sökfält"
            />
            <b-input-group-prepend is-text>
              <inline-svg src="/assets/svg/search.svg"/>
            </b-input-group-prepend>
          </b-input-group>

          <b-table
            style="width: 100%; table-layout: fixed;"
            class="table-striped"
            head-variant="light"
            :fields="todo_headers"
            :items="live_todos"
            :per-page="per_page"
            :current-page="current_page"
            :filter="search"
          >
            <template #cell(action)="{ item }">

              <a class="btn btn-icon btn-light btn-sm mx-1" @click.prevent="manageLiveTodoClicked(item.todo_id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                  
                </span>
              </a>

            </template>
            <template #cell(status)="{ item }">
              {{ statusName[item.status] }}
            </template>
            <template #cell(show_details)="row">
              <b-button variant="link" size="sm" @click="row.toggleDetails" class="mr-2">
                <b-icon v-if="row.detailsShowing" icon="chevron-up" />
                <b-icon v-else icon="chevron-down" />
              </b-button>
            </template>
            <template #row-details="{ item }">
              <TodoChildTable
                type="live"
                :items="item.tasks"
                :item="item"
                @todo_updated="live_todo_updated"
                @show_files_clicked="show_files_clicked"
                @select_row_clicked="select_row_clicked"
                @delete_task_child_clicked="delete_task_child_clicked"
                @show_comments_clicked="show_comments_clicked"
                @update_task_status="update_task_status"
              />
            </template>
          </b-table>

          <TodoEditModal
            ref="todoEdit-active"
            :todo="selected_todo"
            @updated="todo_updated"
            @published="todo_published"
          />
        </v-tab-item>

        <v-tab-item class="mt-8">
          <b-input-group>
            <b-form-input
              v-model="cls_search"
              type="search"
              placeholder="Sökfält"
            />
            <b-input-group-prepend is-text>
              <inline-svg src="/assets/svg/search.svg"/>
            </b-input-group-prepend>
          </b-input-group>

          <b-row>
            <b-col cols="6" >
              <div class="bottom-alignment">
                <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
              </div>

            </b-col>
            <b-col cols="6">
              <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

                <b-form-select
                  class="mr-sm-2 mt-2 align-bottom"
                  style="max-width: 100px;"
                  :options="[100, 500, 1000]"
                  v-model="per_page"
                />

                <b-pagination
                  class="mb-0 mt-2 align-bottom"
                  style="padding-left: 0px !important;"
                  v-model="history_current_page"
                  :total-rows="history_total_rows"
                  :per-page="history_per_page"
                  first-number
                  last-number
                />
              </div>
            </b-col>
          </b-row>

          <b-table
            style="width: 100%; table-layout: fixed;"
            class="table-striped"
            head-variant="light"
            :fields="todo_headers"
            :items="history_todos"
            :per-page="per_page"
            :current-page="current_page"
            :filter="cls_search"
          >
            <template #cell(status)="{ item }">
              {{ statusName[item.status] }}
            </template>
            <template #cell(show_details)="row">
              <b-button variant="link" size="sm" @click="row.toggleDetails" class="mr-2">
                <b-icon v-if="row.detailsShowing" icon="chevron-up" />
                <b-icon v-else icon="chevron-down" />
              </b-button>
            </template>
            <template #row-details="{ item }">
              <TodoChildTable
                type="live"
                :items="item.tasks"
                :item="item"
                @todo_updated="live_todo_updated"
                @show_files_clicked="show_files_clicked"
                @select_row_clicked="select_row_clicked"
                @delete_task_child_clicked="delete_task_child_clicked"
                @show_comments_clicked="show_comments_clicked"
                @update_task_status="update_task_status"
              />
            </template>
          </b-table>

          <TodoEditModal
            ref="todoEdit-finished"
            :todo="selected_todo"
            @updated="todo_updated"
            @published="todo_published"
          />
        </v-tab-item>
      </v-tabs-items>
    </b-card>

    <LiveTaskEdit
      v-if="liveTodoTask"
      ref="editLiveTask"
      :task="liveTodoTask"
      @task_updated="task_updated"
      @close_edit_live_task="close_edit_live_task"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TodoTable from '@/view/pages/ml/todo/TodoTable.vue';
import TodoEditModal from '@/view/pages/ml/todo/TodoEditModal.vue';
import LiveTaskEdit from '@/view/pages/ml/todo/LiveTaskEdit.vue';
import TodoChildTable from '@/view/pages/ml/todo/TodoChildTable.vue';
import FilesListModal from '@/view/pages/ml/todo/FilesListModal.vue';
import ChatBox from '@/view/components/chatBox.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'todo',
  mixins: [ toasts ],
  components: {
    TodoTable,
    TodoEditModal,
    ChatBox,
    TodoChildTable,
    Confirm,
    FilesListModal,
    LiveTaskEdit
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.load_data();
  },
  data() {
    return {
      selected_todo: {},
      liveTodoTask: null,
      activeExpanded: [],
      closedExpanded: [],
      singleExpand: false,
      headerProps: {
        sortByText: 'Sortera'
      },
      todo_headers: [
        { 
          label: 'Ärendenamn', 
          key: 'name', 
          tdClass: 'td-overflow3',
          thClass: 'td-overflow3',
          
          sortable: true,
        },
        {
          label: 'Förening',
          sortable: true,
          tdClass: 'td-overflow2',
          key: 'company.name'
        },
        { 
          label: '# Filer', 
          key: 'num_files',
          tdClass: 'td-short3',
          thClass: 'td-short3',
          sortable: true,
        },
        {
          label: '# Medd.',
          key: 'num_comments',
          tdClass: 'td-short3',
          thClass: 'td-short3',
          sortable: true,
        },
        { 
          label: 'Uppdaterat', 
          key: 'updated_at',
          tdClass: 'td-short5',
          thClass: 'td-short5',
          
          sortable: true,
        },
        { 
          label: 'Status', 
          key: 'status',
          tdClass: 'td-short3',
          thClass: 'td-short3',
          sortable: true,
        },
        { 
          label: '', 
          key: 'action', 
          thClass: 'td-short2', 
          tdClass: 'td-short2' 
        },
        { 
          label: '', 
          key: 'show_details', 
          
          thClass: 'td-short2',
          tdClass: 'td-short2' 

        }
      ],
      selectedTodoId: null,
      selected_tasks: [],
      selected_task: null,
      todos: [],
      live_todos: [],
      current_page: 1,
      per_page: 100,
      draft_todos: [],
      history_todos: [],
      history_current_page: 1,
      history_per_page: 100,
      history_total_rows: 0,
      comments: [],
      comment: '',
      todo_id: null,
      todo_task_id: null,
      columns: ['name', 'code', 'uri'],
      search: '',
      cls_search: '',
      tab: null,
      statusName: {
        DRAFT: 'Utkast',
        INVALID: 'Ej giltig',
        WAIT: 'Väntar',
        NOT: 'Komplettera',
        DONE: 'Klar',
        CN: 'Bekräftat',
        DEL: 'Raderat',
        ACC: 'Accepterat',
        REJ: 'Avslaget',
        BAC: 'Backlog',
        FUT: 'Framtida',
        CLOSE: 'Avslutat'
      },
      todoStatusPrio: {
        REJ: -4,
        CLOSE: -3,
        INVALID: -2,
        WAIT: -1,
        DRAFT: 0,
        NOT: 1,
        DONE: 2,
        CN: 3,
        DEL: 4,
        ACC: 5,
        BAC: 7,
        FUT: 8
      },
      rowsPerPage: [10, 20, 40, -1]
    };
  },
  watch: {

    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_data();
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (newValue) {
        this.load_data();
      }
    }
  },
  methods: {

    draft_todo_updated(todo) {
      const index = this.draft_todos.findIndex(item => item.todo_id === todo.todo_id);

      if (index > -1) {
        this.draft_todos[index] = todo;
        // Vue3 compatability for Vue.set
        this.draft_todos = [...this.draft_todos];
      }
    },


    history_todo_updated(todo) {
      const index = this.history_todos.findIndex(item => item.todo_id === todo.todo_id);

      if (index > -1) {
        this.history_todos[index] = todo;
        // Vue3 compatability for Vue.set
        this.history_todos = [...this.history_todos];
      }
    },

    live_todo_updated(todo) {
      const index = this.live_todos.findIndex(item => item.todo_id === todo.todo_id);

      if (index > -1) {
        this.live_todos[index] = todo;
        // Vue3 compatability for Vue.set
        this.live_todos = [...this.live_todos];
      }
    },

    async delete_file_clicked(file_id, todo_task_id) {
      try {
        const res = await axios.delete(`/todo_task/${todo_task_id}/file/${file_id}`);

        if (res.status === 204) {
          this.selected_task.files = this.selected_task.files.filter(item => item.file_id !== file_id);

          this.selected_task = { ...this.selected_task };

          const index = this.live_todos.findIndex(item => item.todo_id === this.selected_task.todo_id);

          // if found
          if (index > -1) {
            this.live_todos[index].num_files = this.live_todos[index].num_files - 1;
            // Vue3 compatability for Vue.set
            this.live_todos = [...this.live_todos];
          }


          this.toastr('success', this.$t('COMMON.OK'), 'Filen togs bort');

          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera fil');

    },


    async show_files_clicked(todo_task_id) {
      console.log('show files clicked', todo_task_id)
      axios
        .get(`/todo_task/${todo_task_id}`)
        .then(res => {
          this.selected_task = res.data;
          this.$refs['filesListModal'].show();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta åtgärdsdata');
        });
    },


    closeManageModalClicked() {
      this.$refs['manageLiveTodo'].hide();
    },


    closeTodoClicked() {
      this.closeManageModalClicked();
      axios
        .post('/todo/close', { todo_id: this.selectedTodoId })
        .then(res => {
          const todo = this.getTodoWithId(this.selectedTodoId);
          if (res.status === 200 && todo !== null) {
            todo.status = 'CLOSE';
            this.history_todos.push(todo);
            this.todos = this.todos.filter(item => item.todo_id !== this.selectedTodoId);
            this.draft_todos = this.draft_todos.filter(item => item.todo_id !== this.selectedTodoId);
            this.live_todos = this.live_todos.filter(item => item.todo_id !== this.selectedTodoId);
          } else this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte stänga ärendet');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte stänga ärendet');
        });
    },


    getTodoWithId(todo_id) {
      for (var i = 0; i < this.todos.length; ++i) {
        if (this.todos[i].todo_id === todo_id) {
          return this.todos[i];
        }
      }

      return null;
    },


    manageLiveTodoClicked(todo_id) {

      var todo = this.getTodoWithId(todo_id);
      this.selected_tasks = todo.tasks;

      this.selectedTodoId = todo_id;

      this.$refs['manageLiveTodo'].show();
    },
    sendCommentClicked() {
      axios
        .post(`/todo_task/comment/${this.todo_task_id}`, {
          from: 'top',
          comment: this.comment,
          todo_task_id: this.todo_task_id
        })
        .then(res => {
          this.comment = '';
          this.comments.push(res.data);
          this.load_data();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka kommentar');
        });
    },


    show_comments_clicked(todo_task_id) {



      this.todo_task_id = todo_task_id;

      axios
        .get(`/todo_task/${todo_task_id}`)
        .then(res => {
          this.comments = res.data.comments;

          this.$refs['chatBoxModal'].show();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta kommentarer');
        });
    },

    task_updated(todo_task_id) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.SUBTASK_UPDATED'));
    },

    close_edit_live_task() {
      this.liveTodoTask = null;
    },


    select_row_clicked(todo_task_id) {

      // this solution is shit
      var that = this;

      for (const todo of this.live_todos) {
        for (const task of todo.tasks) {
          if (task.todo_task_id === todo_task_id) {
            this.liveTodoTask = task;

            setTimeout(function() {
              that.$refs['editLiveTask'].init();
            }, 100);

            return;
          }
        }
      }
    },


    delete_task_child_clicked(todo_task_id) {
      axios
        .delete(`/todo_task/${todo_task_id}`)
        .then(res => {
          if (res.status === 204) {
            this.live_todos.filter((todo, i) => {
              todo.tasks.filter((task, t) => {
                if (task.todo_task_id === todo_task_id) {
                  this.live_todos[i].tasks.splice(t, 1);
                }
              });
            });

            this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.SUBTASK_DELETED'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_DELETE_SUBTASK'));
        });

    },
    update_task_status() {
      this.load_data();
    },
    todo_updated(todo) {
      this.load_data();
    },
    todo_published(todo) {
      this.load_data();
    },

    async load_data() {
      const loader = this.$loading.show();

      try {

        const res = await axios.get(`/todo?type=PA&creator_company_id=${this.currentCompanyId}`)

        if (res.status === 200) {

          this.todos = res.data;

          this.live_todos = [];
          this.draft_todos = [];
          this.history_todos = [];

          for (var i = 0; i < this.todos.length; ++i) {
            var t = this.todos[i];

            if (t.status === 'INVALID' || t.status === 'DRAFT') {
              this.draft_todos.push(t);
            } else if (t.status === 'DEL' || t.status === 'CLOSE') {
              this.history_todos.push(t);
            } else {
              //else if (t.status === 'NOT' || t.status === 'WAIT' || t.status === 'DONE' || t.status === 'CN' || t.status === 'ACC' || t.status === 'REJ') {
              this.live_todos.push(t);
            }
          }
          this.live_todos.map(function(item) {
            if (item.company_name == '') item.company_name = this.$t('COMMON.ALL');
          });

          loader && loader.hide();

          return;
        }
      }
      catch (err) {
        console.error(err);

      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_GET'));

      loader && loader.hide();

    },


    create_todo_clicked() {
      const tab_ref = this.selectedTab();
      this.$refs[`${tab_ref}`].show();
      this.selected_todo = {}
      this.$refs[`${tab_ref}`].createTodo();
    },


    select_todo_clicked(todo_id) {
      const tab_ref = this.selectedTab();

      const todo = this.todos.find(item => item.todo_id === todo_id);

      this.selected_todo = { ...todo };

      this.$refs[`${tab_ref}`].show();
    },

    /// when the todo has been published from the Editor
    published(todo) {
      const draft_item_index = this.draft_todos.findIndex(item => item.todo_id === todo.todo_id);
      const item_index = this.todos.findIndex(item => item.todo_id === todo.todo_id);

      if (draft_item_index >= 0) {
        this.draft_todos.splice(0, draft_item_index);
      }

      if (item_index >= 0) {
        this.todos.splice(0, item_index);
      }
    },

    async delete_todo_clicked(todo_id) {

      try {
        this.closeManageModalClicked();
        const res = await axios.delete(`/todo/${todo_id}`)

        if (res.status === 204) {
          this.todos = this.todos.filter(item => item.todo_id !== todo_id);
          this.draft_todos = this.draft_todos.filter(item => item.todo_id !== todo_id);
          this.live_todos = this.live_todos.filter(item => item.todo_id !== todo_id);
          this.history_todos = this.history_todos.filter(item => item.todo_id !== todo_id);
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TODO.DELETED'));

          return;
        }
      }
      catch (err) {
        console.error('delete_todo_clicked error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TODO.UNABLE_DELETE'));
    },

    confirm_delete() {
      this.$refs['confirmModal'].show();
    },

    selectedTab() {
      let tab = '';
      if (this.tab === 0) tab = 'todoEdit-draft';
      if (this.tab === 1) tab = 'todoEdit-active';
      if (this.tab === 2) tab = 'todoEdit-finished';
      return tab;
    },

  }
};
</script>

<style lang="scss" scoped>


:deep th div {
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep .tr-orange {
  background-color: #ffb85d !important;
}

:deep .tr-red {
  background-color: #ffc7c7 !important;
}


:deep .td-overflow2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
}

:deep .td-overflow3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 40%;
  min-width: 300px;
}

:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

:deep td {
  vertical-align: middle !important;
}
</style>
