<template>
  <tr>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ file.name }}</span>
    </td>
    <td>{{ file.created_at }}</td>
    <td class="text-right pr-0">
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click.prevent="downloadFileClicked"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click.prevent="deleteFileClicked"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
.hplus {
  cursor: pointer;
}
.hminus {
  cursor: pointer;
}
</style>
<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'file-table-row',
  props: ['file'],
  emits: ['deleteFileClicked'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId']),
  },
  data() {
    return {

    };
  },
  mounted() {},
  watch: {},
  methods: {

    downloadFileClicked() {
      downloadWithAxios(get_base_url() + `/dlfile/${this.file.linkstr}`, this.file.name);
    },
    deleteFileClicked() {
      this.$emit('deleteFileClicked', this.file.file_id);
    },
  }
};
</script>
