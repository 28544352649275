<template>
  <div class="b-table-sticky-header table-responsive" style="max-width: calc(100vw - 128px)">
    <table class="table b-table table-head-custom table-vertical-center table-head-bg table-borderless">
      <thead>
        <tr class="text-left">
          <th>Åtgärd</th>
          <th class="updated_at">Uppdaterat</th>
          <th class="status">Status</th>
          <th class="comment">Kommentar</th>
          <th class="action">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, i) in items">
          <TodoChildTableRow
            v-bind:key="i"
            :row="item"
            :preventEditing="preventEditing"
            @todo_updated="todo_updated"
            @show_files_clicked="show_files_clicked"
            @show_comments_clicked="show_comments_clicked"
            @select_row_clicked="select_row_clicked"
            @delete_row_clicked="delete_row_clicked"
            @update_task_status="update_task_status"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.b-table-sticky-header{
  overflow-y: hidden;
  max-height: unset;
}

th {
  padding-bottom: 4px !important;
}
  .updated_at{
    min-width: 140px;
    width: 200px;
  }
  .status {
    min-width: 133px;
    width: 145px;
  }
  .comment {
    min-width: 96px;
    width: 96px;
  }
  .action {
    width: 236px;
    min-width: 194px;
  }
</style>

<script>
import axios from 'axios';
import TodoChildTableRow from '@/view/pages/ml/todo/TodoChildTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'child-table',
  props: ['item', 'items', 'currentCompanyId', 'type', 'preventEditing'],
  emits: ['updateTodo', 'show_files_clicked', 'show_comments_clicked', 'select_row_clicked', 'delete_row_clicked', 'update_task_status'],
  mixins: [ toasts ],
  components: {
    TodoChildTableRow
  },
  watch: {
    items(newValue, oldValue) {

    }
  },
  mounted() {
    if (this.type === 'draft') {
      this.title = 'Ärendenamn';
    }
    else {
      this.title = 'Förening';
    }

  },
  methods: {
    show_files_clicked(todo_task_id) {
      this.$emit('show_files_clicked', todo_task_id);
    },
    show_comments_clicked(todo_task_id) {
      this.$emit('show_comments_clicked', todo_task_id);
    },
    select_row_clicked(todo_task_id) {
      this.$emit('select_row_clicked', todo_task_id);
    },
    delete_row_clicked(todo_task_id) {
      this.$emit('delete_row_clicked', todo_task_id);
    },
    update_task_status(){
      this.$emit('update_task_status');
    },
    todo_updated(todo) {
      this.$emit('updateTodo', todo);
    },

  },
  data() {
    return {
      expanded: {},
      list: [],
      search: '',
      title: '',
      rows: [],
      groups: {}
    };
  }
};
</script>

