<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <b-row align-v="end" v-if="type === 'draft'" class="card-header border-0 py-5">
      <b-col align="start" md="auto">
        <b-input-group>
          <b-form-input
            v-model="search"
            type="search"
            style="min-width: 380px;"
            :placeholder="searchLabel"
          />
          <b-input-group-prepend is-text>
            <inline-svg src="/assets/svg/search.svg" />
          </b-input-group-prepend>
        </b-input-group>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          style="max-height: 38px;"
          @click.prevent="create_todo_clicked"
        >
          <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{ $t('TODO.NEW') }}
        </a>
      </b-col>
    </b-row>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3 mt-4">
      <b-input-group v-if="type !== 'draft'">
        <b-form-input
          v-model="search"
          type="search"
          :placeholder="searchLabel"
        />
        <b-input-group-prepend is-text>
          <inline-svg src="/assets/svg/search.svg" />
        </b-input-group-prepend>
      </b-input-group>

      <b-row>
        <b-col cols="6" >
          <div class="bottom-alignment">
            <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
          </div>

        </b-col>
        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              style="padding-left: 0px !important;"
              v-model="current_page"
              :total-rows="total_rows"
              :per-page="per_page"
              first-number
              last-number
            />
          </div>
        </b-col>
      </b-row>

      <!--begin::Table-->
      <b-table
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        head-variant="light"
        :fields="todo_headers"
        :items="todos"
        :per-page="per_page"
        :current-page="current_page"
        :filter="search"
      >
        <template #cell(status)="{ item }">
          {{ statusName[item.status] }}
        </template>
        <template #cell(action)="{ item }">
          <div class="text-right pr-0">
            <a
              v-if="type !== 'draft'"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="show_comments_clicked(item.todo_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/chat.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>


            <a
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="select_todo_clicked(item.todo_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

            <a
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="delete_todo_clicked(item.todo_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

          </div>
        </template>
      </b-table>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<script>
import TodoTableRow from '@/view/pages/ml/todo/TodoTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'todos-table',
  props: ['todos', 'type', 'statusName', 'searchLabel'],
  emits: ['show_comments_clicked', 'publish_todo_clicked', 'select_todo_clicked', 'delete_todo_clicked', 'showChildren', 'create_todo_clicked'],
  mixins: [ toasts ],
  components: {
    TodoTableRow
  },
  data() {
    return {
      headerProps: {
        sortByText: 'Sortera'
      },
      expanded: {},
      list: [],
      search: '',
      current_page: 1,
      per_page: 100,
      total_rows: 0,
      title: '',
    };
  },
  methods: {
    show_comments_clicked(todo_id) {
      this.$emit('show_comments_clicked', todo_id);
    },
    publish_todo_clicked(todo_id) {
      this.$emit('publish_todo_clicked', todo_id);
    },
    select_todo_clicked(todo_id) {
      this.$emit('select_todo_clicked', todo_id);
    },
    delete_todo_clicked(todo_id) {
      this.$emit('delete_todo_clicked', todo_id);
    },
    showChildren(todo_id, show) {
      this.$emit('showChildren', todo_id, show);
    },
    create_todo_clicked() {
      this.$emit('create_todo_clicked');
    },

  },
  computed: {
    todo_headers() {
      return [
        { tdClass: 'td-overflow3',
          thClass: 'td-overflow3',
          label: this.type === 'draft' ? 'Ärendenamn' : 'Förening', key: 'name' },
        { 
          thClass: 'td-short3', 
          tdClass: 'td-short3', label: 'Status', key: 'status' },
        { label: 'Från Datum', key: 'from_datetime' },
        { label: 'Till Datum', key: 'to_datetime' },
        { label: '', key: 'action' },
      ]
    },
  },
  watch: {
    todos(new_val) {
      this.total_rows = new_val.length
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

  .card.card-custom > .card-body {
    padding: 0 !important;
  }
  .card.card-custom > .card-header {
    padding: 0 !important;
  }
  .checkbox > span {
    background-color: white;
    border: 1px solid #ddd;
  }
  .hplus {
    cursor: pointer;
  }
  .hminus {
    cursor: pointer;
  }

  :deep th div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :deep .tr-orange {
    background-color: #ffb85d !important;
  }

  :deep .tr-red {
    background-color: #ffc7c7 !important;
  }

  :deep .td-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 25%;
  }

  :deep .td-short {
    width: 32px;
  }

  :deep .td-short2 {
    width: 56px;
  }

  :deep .td-short3 {
    width: 76px;
  }

  :deep .td-short4 {
    width: 96px;
  }

  :deep .td-short5 {
    width: 116px;
  }

  :deep td {
    vertical-align: middle !important;
  }

  :deep .td-overflow3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 40%;
  min-width: 300px;
}
</style>
